
.savebutton .btn {
    float: right;
    background-color: #fd4c93;
    border: none;
    color: white;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
  }
  
  .initial_appraisal_container {
    margin: 30px ;
    padding: 1%;
    height: 100%;
  }
  
  .bg-container {
    background-color: rgb(255, 255, 255);
    min-height: max-content;
    padding: 1%;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  }
  
  .padding {
    padding: 1%;
  }
  
  .formLabel {
    float: right;
    font-size: large;
    font-weight: 500;
    flex-direction: row;
  }
  
  .formHeading {
    font-size: xx-large;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  .card-header-{
    font-size: xx-large;
    font-family: Georgia, 'Times New Roman', Times, serif;
    align-items: center; 
  }
  
  .Appraisal-listtable th {
    background-color: var(--bg-purple) !important;
    color: #fff !important;
    border: none;
  }
  
  .Appraisal-list-table thead tr th {
    vertical-align: middle;
    text-align: left;
    font-size: 14px;
  }

  @media (min-width: 1400px) {
    .initial_appraisal_container {
        max-width: 1600px !important;
    }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the overlay is above other content */
}

.spinner {
  color: #ffffff; /* Spinner color */
}