.Hov:hover{
    cursor: pointer;

}

.self_assessment_container {
	background-color: #EBF2FC;
}

.self_assessment_table th {
  background-color: var(--bg-purple) !important;
  color: #fff !important;
  border: none;
}

.self_assessment_table {
	width: 100%;
	border-collapse: collapse;
}


select.rating_filter {
	border: none;
	background-color: #e1e1e1;
    padding: 0.5rem;
}

.file-upload-label {
	background-color: #f0f0f0;
	border-radius: 5px;
	margin-top: 18px;
}

.file-upload-label:hover {
	background-color: #e0e0e0;
}

.self_assessment_table thead th {
	padding: 10px; /* Adjust padding as needed */
	background-color: #f0f0f0; /* Set background color for table header */
	border-bottom: 1px solid #ccc; /* Add a bottom border to separate header from body */
}




.self_assessment_table tbody td {
	padding: 10px; /* Adjust padding as needed */
  border: none;
}

.table_heading {
	font-size: 12px;
	font-weight: 700;
	text-align: center;
}

.goal_button .btn {
	background-color: #fd4c93;
	border: none;
}

.goal_button .btn:hover {
	background-color: #fd4c93;
	border: none;
}

.form_buttons .btn-link {
	color: black !important;
	outline: none !important;
}

.form_buttons .btn-success {
	background-color: var(--bg-purple) !important;
	outline: none !important;
}

.form_buttons .btn-secondary {
	background-color: var(--bg-purple) !important;
	outline: none !important;
}

.form_buttons .btn-link:focus,.btn-link:active {
	outline: none !important;
	box-shadow: none;
}

.form_buttons .btn-success:focus,.btn-success:active {
	outline: none !important;
	box-shadow: none;
}

.form_buttons .btn-secondary:focus,.btn-secondary:active {
	outline: none !important;
	box-shadow: none;
}

.table_body tr td {
	padding: 16px;
	font-size: 14px;
	vertical-align: middle;
	text-align: center;
}

.table_body tr td span {
	font-size: 12px;
}

.card_details {
	font-size: 15px;
}

.card_heading {
	font-size: 18px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.trash_icon {
	font-size: 1.5em;
	color: red;
    cursor: pointer;
}

.form_buttons .btn-secondary {
	background-color: var(--bg-purple) !important;
	outline: none !important;
}

.form_buttons .btn-link:focus,.btn-link:active {
	outline: none !important;
	box-shadow: none;
}

.form_buttons .btn-success:focus,.btn-success:active {
	outline: none !important;
	box-shadow: none;
}

.form_buttons .btn-secondary:focus,.btn-secondary:active {
	outline: none !important;
	box-shadow: none;
}

.table_body tr td {
	padding: 16px;
	font-size: 14px;
	vertical-align: middle;
	text-align: center;
}

.table_body tr .text_td {
    text-align: left;
    font-weight: 500;
}

.table_body tr .text_center_td {
    text-align: center;
    font-weight: 500;
}

.table_body tr td span {
	font-size: 12px;
}

.card_heading {
	font-size: 18px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.trash_icon {
	font-size: 1.5em;
	color: red;
    cursor: pointer;
}

.self_assessment_table thead tr th {
    vertical-align: middle;
    text-align: left;
    font-size: 14px;
	padding-left: 16px;
}

.attach_docs_div {
    display: flex;
    flex-direction: column;
}

.header_text_center {
	text-align: center;
}

.escalation_div {
	text-align: end;
	padding: 8px 24px 16px 8px;
}

.form_buttons textarea.form-control {
	width: 50% !important;
}

@media (min-width: 1400px) {
    .self_assessment_container {
        max-width: 1600px !important;
    }
}

/* Style for the overall container */
.rating-container {
    text-align: center;
    padding: 20px;

    border-radius: 10px;
  }
  
  .rating-row {
    display: flex;
    flex-direction: row;
    align-items: center;
	justify-content: center;
  }
  
  .rating-col {
    margin: 10px;
    padding: 15px;
    border-radius: 8px;
    font-weight: bold;
    text-align: center;
  }
  .rating-5 {
    background-color: #4caf50;
    color: white;
  }
  
  .rating-4 {
    background-color: #8bc34a;
    color: white;
  }
  
  .rating-3 {
    background-color: #ffc107;
    color: white;
  }
  
  .rating-2 {
    background-color: #ff9800;
    color: white;
  }
  
  .rating-1 {
    background-color: #f44336;
    color: white;
  }
  

  @media (min-width: 1400px) {
    .teams-container {
        max-width: 1600px !important;
    }
}


.dis {
	pointer-events: none !important;
	opacity: 0.5; /* You can adjust the opacity or any other styling properties based on your preference */
	cursor: not-allowed n !important;
  }
  thead th {
    padding: 10px;
    background-color:  var(--bg-purple) !important;
    color: #fff;
    border-bottom: 2px solid #ccc;
}
.table_body tr.text_td1 {
	background-color: #f8f9fa; /* Light gray to show non-editable */
	cursor: not-allowed;      /* Show a disabled cursor */
  }
  
  .table_heading1 {
	font-weight: bold;
	text-align: left;
  }