  #chart-card {
        overflow: auto;
        height:100%;
    }
  .skill-button{
    margin-right: 5%;
  }

  @media (min-width: 1400px) {
    .reports_container {
      max-width: 1600px !important;
    }
  }