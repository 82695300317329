/* hr_myappraisal.css */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main-content {
   
    background-color: #EBF2FC;
}
.table_body tr .text_center_td {
  text-align: center;
  font-weight: 500;
}

.table_body tr td {
	padding: 16px;
	font-size: 14px;
	vertical-align: middle;
	text-align: center;
}

.table_body tr .text_td {
    text-align: left;
    font-weight: 500;
}

.table_body tr .text_center_td {
    text-align: center;
    font-weight: 500;
}

.table_body tr td span {
	font-size: 12px;
}

.self_assessment_table thead tr th {
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
padding-left: 16px;
}

.form_buttons textarea.form-control {
	width: 50% !important;
}

@media (min-width: 1400px) {
  .self_assessment_container {
      max-width: 1600px !important;
  }
}

  
  
  .card-title {
    color: #333;
  }
  
  .profile-info {
    margin-bottom: 20px;
  }
  
  .profile-icon {
   
    margin-bottom: 10px;
  }
  
  .profile-icon svg {
    fill: white;
  }
  
  /* p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 15px;
    font-weight: 600;
} */
span.appraisal_status {
    background-color: #8ce18c;
    border-radius: 3px;
}
.Profile_comment {
    display: flex;
    gap: 125px;
}
.vl {
    border-left: 2px solid #dbdada;
    height: 7rem;
}

.table_heading {
  background-color: #ffc107 !important;
  color: #333;
  border: none;
}
.hov:hover{
  cursor: pointer;
}

  
 
  span.card-title {
    font-size: 16px;
    font-weight: 600;
    padding-left: 14px;
}
p.Profile_name {
    color: var(--bg-purple);
}
  
  .card-body h3 {
    color: #333;
  }
  span.comment_view {
    font-size: 18px;
    font-weight: 500;
    line-height: 51px;
}
span.comment {
    font-size: 12px;
    font-weight: 400;
}

  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
 
  
  .table th {
    color: #333;
}
.profile-info {
    display: flex;
    align-items: center; /* Center items vertically */
}

.profile-icon {
    margin-right: 10px; /* Adjust margin between the icon and text */
    display: flex; /* Use flexbox for the icon container */
    align-items: center; /* Center the icon horizontally */
    justify-content: center; /* Center the icon vertically */
    width: 50px; /* Set the width of the icon container */
    height: 50px; /* Set the height of the icon container */
}
.l1_managercontent {
    line-height: 32px;
}
.manager_content {
    font-size: 15px;
    font-weight: 500;
}
.profile-details {
    margin-top: 14px;
    line-height: 11px;
    padding-right: 34px;
}
span.name {
    color: var(--bg-purple);
}

  

  .col-md-12.appraisal_tabel {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #fff;
}
select.rating_filter {
    border: none;
    background-color: #e1e1e1;
}
.file-upload-label {
    background-color: #f0f0f0;
    border-radius: 5px;
    margin-top: 18px;
}



.file-upload-label:hover {
    background-color: #e0e0e0;
}
.table {
    border-collapse: collapse; /* Ensures there is no spacing between table cells */
    width: 100%; /* Set the width of the table */
}

thead th {
    padding: 10px; /* Adjust padding as needed */
    background-color: #f0f0f0; /* Set background color for table header */
    border-bottom: 2px solid #ccc; /* Add a bottom border to separate header from body */
}

tbody td {
    padding: 10px; /* Adjust padding as needed */
}


th.table_heading {
    font-size: 12px;
    font-weight: 700;
}

.file-upload-label i {
    margin-right: 5px; /* Adjust margin between icon and text */
}

.file-upload-label:hover {
    background-color: #e0e0e0;
}
.Appraisal_status {
    display: flex;
    justify-content: space-between;
} 
  .print_icon{
    font-size: 18px;
    border: 1px solid black;
    padding: 1px
  }

  .self_assessment_container {
    background-color: #EBF2FC;
  }
  
  .self_assessment_table th {
    background-color: var(--bg-purple) !important;
    color: #fff !important;
    border: none;
  }
  
  .self_assessment_table {
    width: 100%;
    border-collapse: collapse;
  }
  
  select.rating_filter {
    border: none;
    background-color: #e1e1e1;
  }
  
  .file-upload-label {
    background-color: #f0f0f0;
    border-radius: 5px;
    margin-top: 18px;
  }
  
  .file-upload-label:hover {
    background-color: #e0e0e0;
  }
  
  .self_assessment_table thead th {
    padding: 10px; /* Adjust padding as needed */
    background-color: #f0f0f0; /* Set background color for table header */
    border-bottom: 1px solid #ccc; /* Add a bottom border to separate header from body */
  }
  
  .self_assessment_table tbody td {
    padding: 10px; /* Adjust padding as needed */
    border: none;
  }
  
  .table_heading {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
  
  .goal_button .btn {
    background-color: #fd4c93;
    border: none;
  }
  
  .goal_button .btn:hover {
    background-color: #fd4c93;
    border: none;
  }
  
  .form_buttons .btn-link {
    color: black !important;
    outline: none !important;
  }
  
  .form_buttons .btn-success {
    background-color: var(--bg-purple) !important;
    outline: none !important;
  }
  
  .form_buttons .btn-secondary {
    background-color: var(--bg-purple) !important;
    outline: none !important;
  }
  
  .form_buttons .btn-link:focus,.btn-link:active {
    outline: none !important;
    box-shadow: none;
  }
  
  .form_buttons .btn-success:focus,.btn-success:active {
    outline: none !important;
    box-shadow: none;
  }
  
  .form_buttons .btn-secondary:focus,.btn-secondary:active {
    outline: none !important;
    box-shadow: none;
  }
  
  .table_body tr td {
    padding: 16px;
    font-size: 14px;
    vertical-align: middle;
 
  }
  
  .table_body tr td span {
    font-size: 12px;
  }
  
  .card_details {
    font-size: 15px;
  }
  
  .card_heading {
    font-size: 18px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  .trash_icon {
    font-size: 1.5em;
    color: red;
      cursor: pointer;
  }
  
