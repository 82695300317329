:root {
	--bg-purple: #6F4BF2;
	--bg-purple-rgb: rgb(111, 75, 242);
	--bg-purple-code: 111, 75, 242;

	--bg-pink: #cf6ccf;
	--bg-pink-rgb: rgb(207, 108, 207);
	--bg-pink-code: 207, 108, 207;

	--bg-blue: #986ad9;
	--bg-blue-rgb: rgb(152, 106, 217);
	--bg-blue-code: 152, 106, 217;

	--bg-red: #986ad9;
	--bg-red-rgb: rgb(152, 106, 217);
	--bg-red-code: 152, 106, 217;

	/* Color Theme Swatches in Hex */
	--bg-shade-pink-hex: #ceb3f2;
	--bg-shade-purple-hex: #aba0f2;
	--bg-shade-dark-blue-hex: #021f59;
	--bg-shade-light-blue-hex: #023e73;
	--bg-shade-hazel-blue-hex: #023059;

	/* Color Theme Swatches in RGBA */
	--bg-shade-pink-rgba: rgba(205, 179, 242, 1); 
	--bg-shade-purple-rgba: rgba(111, 75, 242, 1);
	--bg-shade-dark-blue-rgba: rgba(1, 30, 89, 1);
	--bg-shade-light-blue-rgba: rgba(2, 62, 114, 1);
	--bg-shade-hazel-blue-rgba: rgba(1, 48, 89, 1);

	/* Color Theme Swatches in HSLA */
	--bg-shade-pink-hsla: hsla(264, 71, 82, 1);
	--bg-shade-purple-hsla: hsla(247, 76, 78, 1);
	--bg-shade-dark-blue-hsla: hsla(219, 96, 17, 1);
	--bg-shade-light-blue-hsla: hsla(208, 96, 22, 1);
	--bg-shade-hazel-blue-hsla: hsla(208, 96, 17, 1);
}
/* Customize Scrollbar */

/* width */
/* width */
::-webkit-scrollbar {
	width: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2) !important;
	border-radius: 8px !important;
	transition: all 300ms ease !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.4) !important;
}

.App {
	display: flex;
	width: 100%;
	align-items: stretch;
}

body {
	font-family: "Poppins", sans-serif;
	background: #fafafa;
}

a,
a:hover,
a:focus {
	color: inherit;
	text-decoration: none;
	transition: all 0.3s;
}

/* ---------------------------------------------------
    LAYOUT STYLE
----------------------------------------------------- */

.layout {
	background-color: rgb(237, 241, 247);
	color: rgb(34, 43, 69);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.25rem;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	min-width: 300px;
	--bs-gutter-x: 0 !important;
	--bs-gutter-y: 0 !important;
}

.nav_bar {
	height: 8vh;
	width: 100vw;
	justify-content: left;
}

.search_div {
	margin-left: 2vw;
	width: 40vw;
	position: relative;
}

.search_div input {
	width: 40vw;
	padding: 12px;
	border-radius: 5px;
	border: none;
	outline: none;
	background: #ebf2fc;
}

.search_div .bi-search {
	position: absolute;
	right: 16px;
	bottom: 50%;
	transform: translateY(50%);
}

.footer_div {
	position: absolute;
	bottom: 0;
	width: 98vw;
	height: 7vh;
	overflow: hidden;
}

/* ---------------------------------------------------
HEADER STYLE
----------------------------------------------------- */
header {
	background-color: rgb(255, 255, 255);
	color: rgb(34, 43, 69);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.25rem;
	display: block;

	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 1040;
}

header nav {
	color: rgb(34, 43, 69);
	box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px -1px;
	height: 4.75rem;
	padding: 1.25rem;
	-moz-box-align: center;
	align-items: center;
	-moz-box-pack: start;
	justify-content: flex-start;
	display: flex;
}
header nav a {
	color: rgb(34, 43, 69);
}
header nav .navbar-wrap {
	display: flex;
	width: 100%;
	-moz-box-pack: justify;
	justify-content: space-between;
}

header nav .navbar-wrap .navbar-left,
header nav .navbar-wrap .navbar-center,
header nav .navbar-wrap .navbar-right {
	background-color: transparent;
	color: rgb(34, 43, 69);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-weight: 700;
	line-height: 1rem;
	display: flex;
	-moz-box-align: center;
	align-items: center;
}
header nav .navbar-wrap .navbar-left div {
	display: flex;
	flex-wrap: wrap;
	-moz-box-align: center;
	align-items: center;
	position: relative;
	font-size: 0.875rem;
	height: 2.25rem;
	padding: 0px 1.25rem;
	border-left: 1px solid rgb(237, 241, 247);
	background: transparent;

	color: rgb(34, 43, 69);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-weight: 700;
	line-height: 1rem;
}
header nav .navbar-wrap .navbar-left div:first-child {
	border-left: medium !important;
	padding-left: 0;
}
.control-icon {
	font-size: 2em;
	color: rgb(143, 155, 179);
	line-height: 1;
	width: 1em;
	height: 1em;
	display: inline-block;
}
.control-icon svg {
	vertical-align: top;
}
header nav .navbar-wrap .navbar-left .logo {
	font-size: 1.25rem;
	white-space: nowrap;
	text-decoration: none;
}
.navbar-center {
}
.navbar-right .user-container .dropdown .dropdown-toggle {
	position: relative;
	display: flex !important;
	-moz-box-align: center;
	align-items: center;

	background-color: rgba(255, 255, 255, 0.88);
	border: 1px solid #dadce0;
	cursor: pointer;
	display: inline-block;
	max-height: 48px;
	outline: none;
	padding: 0;
	vertical-align: middle;
	-webkit-border-radius: 8px;
	border-radius: 8px;
	padding: 0.8rem 0.2rem;
}

.navbar-right .user-container .dropdown .dropdown-toggle::after {
	display: none;
	position: absolute;
	right: -26px;
	top: 50%;
	transform: translateY(50%);
	border-top: 0.4em solid;
	border-right: 0.4em solid transparent;
	border-bottom: 0;
	border-left: 0.4em solid transparent;
}
.navbar-right .user-picture.image {
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url("");
	border-radius: 8px;
	overflow: hidden;
}
.navbar-right .user-picture {
	height: 2.5rem;
	width: 2.5rem;
	background-color: transparent;
	border: 1px solid rgb(237, 241, 247);
	position: relative;
	border-radius: 50%;
	flex-shrink: 0;
}
.navbar-right .info-container {
	margin-left: 0.5rem;
}
.navbar-right .user-name {
	font-size: 0.9375rem;
	line-height: 1.25rem;
}
.navbar-right .user-name,
.navbar-right .user-title {
	text-align: left;
}
.navbar-right .user-name {
	color: rgb(34, 43, 69);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-weight: 600 !important;
	text-transform: capitalize;
}

.navbar-right .user-title {
	font-size: 0.75rem;
	line-height: 1rem;
}

.navbar-right .user-title {
	color: rgb(34, 43, 69);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-weight: 400;
	font-size: 0.75rem;
}

/* ---------------------------------------------------
    MAIN STYLE
----------------------------------------------------- */

main {
	/* padding-top: 4.75rem; */
	min-height: 100vh;
	min-height: calc(-4.75rem + 100vh);
	display: flex;
	flex: 1 1 0%;
	flex-direction: row;
}
.template-main {
	padding-top: 4.75rem;
	min-height: 100vh;
	min-height: calc(-4.75rem + 100vh);
	display: flex;
	flex: 1 1 0%;
	flex-direction: row;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.aside-open {
	background-color: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px -1px;
	color: rgb(34, 43, 69);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.25rem;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	z-index: auto;
	transition: width 0.3s cubic-bezier(0.4, 0, 0.6, 1) 0ms;
	order: 0;
	width: 16rem;
}
.aside-closed {
	background-color: rgb(255, 255, 255);
	box-shadow: rgba(44, 51, 73, 0.1) 0px 0.5rem 1rem 0px;
	color: rgb(34, 43, 69);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.25rem;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	z-index: auto;
	transition: width 0.3s cubic-bezier(0.4, 0, 0.6, 1) 0ms;
	order: 0;
}

.aside-open .main-container {
	height: calc(-4.75rem + 100vh);
	transform: translate3d(0px, 0px, 0px);
	display: flex;
	flex-direction: column;
	transition: width 0.3s cubic-bezier(0.4, 0, 0.6, 1) 0ms;
	width: 16rem;
	position: fixed;
}

.aside-closed .main-container {
	height: calc(-4.75rem + 100vh);
	transform: translate3d(0px, 0px, 0px);
	display: flex;
	flex-direction: column;
	transition: width 0.3s cubic-bezier(0.4, 0, 0.6, 1) 0ms;
	position: fixed;
}

.aside-closed,
.aside-closed .main-container {
	width: 4rem;
}

aside .menu-items {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	overflow: hidden;
}
aside .menu-items .menu-item {
	border-bottom: 1px solid rgb(237, 241, 247);
	color: rgb(34, 43, 69);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.25rem;
}
aside .menu-items .menu-item:first-child {
	border-top: medium;
}
aside .menu-items .menu-item a {
	display: flex;
	color: inherit;
	text-decoration: none;
	-moz-box-align: center;
	align-items: center;

	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	padding: 0.75rem 1rem;
	gap: 20px;
}
aside .menu-items .menu-item a i {
	font-size: 1.2rem;
	padding-left: 8px;
}
aside .menu-items .menu-item .menu-icon {
	color: rgb(143, 155, 179);
	font-size: 1.5rem;
	margin: 0px 0.5rem 0px 0px;
	width: 1em;
	text-align: center;
	line-height: 1;
	display: inline-block;
	height: 1em;
}

aside .menu-items .menu-item a .menu-title {
	flex: 1 0 auto;
}
.sidebar-header {
	background: #fff;
}

.sidebar-header h3 {
	color: black;
	padding: 1rem;
}

.sidebar ul p {
	color: black;
	padding: 10px;
}

.menu-open {
	background: #6d7fcc;
}
.side-menu .nav-item {
	position: relative;
}
.side-menu .nav-item .nav-link {
	transition: all 300ms ease !important;
}
.side-menu .nav-item.active .nav-link,
.side-menu .nav-item.active .nav-link:hover {
	color: var(--bg-shade-light-blue-rgba) !important;
}
.side-menu .nav-item .nav-link:hover {
	color: var(--bg-shade-purple-rgba) !important;
	/* background-image: linear-gradient(120deg, #c4adfb 0%, var(--bg-purple) 100%); */
}
.side-menu .nav-item.active .nav-link {
	--active-bg: 170, 159, 242;
	background-color: rgba(var(--active-bg), 0.2);
}
.side-menu .nav-item .nav-link:focus {
	outline: none;
}
.side-menu .nav-item::after {
	position: absolute;
	inset-block: 0;
	inset-inline-end: 0;
	border-inline-end: 3px solid var(--bg-shade-purple-rgba);
	transform: scaleY(0.0001);
	opacity: 0;
	transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
		opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
	content: "";
}

.side-menu .nav-item.active::after {
	transform: scaleY(1);
	opacity: 1;
	transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
		opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.nav-link {
	color: var(--bg-shade-dark-blue-rgba) !important;
	cursor: pointer;
	text-decoration: none !important;
}

.nav-link:hover {
	color: var(--bg-shade-purple-rgba) !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
	color: var(--bg-shade-purple-rgba) !important;
    border-color: var(--bg-shade-purple-rgba) transparent transparent !important;
    background-color: #fff !important;
	border-radius: 0;box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px -1px;
}

.items-menu {
	color: #fff;
	background: #6d7fcc;
}

li a.dropdown-toggle::after {
	display: inline-flex;
	position: relative;
	left: 60%;
	top: 10%;
}

.sidebar-header > span {
	position: relative;
	float: right;
	margin: 0.5em;
	font-size: 2rem;
	cursor: pointer;
	display: none;
}
.side-menu {
	flex: 1 1 0%;
	position: relative;
	transform: translate3d(0px, 0px, 0px);
	overflow: hidden auto;
	padding: 1.25rem 0;
}

.side-menu::-webkit-scrollbar {
	width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
	background: #5466b3;
	border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
	background: #3a4c99;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.content-wrap {
	display: flex;
	flex: 1 1 0%;
	flex-direction: column;
	min-width: 0px;
}
.content-area {
	display: flex;
	flex: 1 1 0%;
	flex-direction: column;
	width: calc(100% - 60px);
	margin: 30px;
}

.content-wrap > footer {
	position: relative;
	bottom: 0;
	display: block;
	margin-top: auto;
	border-top: 1px solid rgb(230, 235, 241);
}
.content-wrap footer nav {
	-moz-box-pack: center;
	justify-content: center;
	display: flex;
	border-top: 1px solid rgb(237, 241, 247);
	color: rgb(34, 43, 69);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.25rem;
	padding: 1.25rem;
}
@media only screen and (max-width: 500px) {
	body {
		overflow: hidden;
	}

	.sidebar.is-open {
		min-width: 100%;
		max-width: 100%;
		margin-left: 0;
		transition: all 0.5s, height 0s;
	}

	.sidebar.is-open > .sidebar-header span {
		display: unset;
	}

	li a.dropdown-toggle::after {
		display: inline-block;
		position: relative;
		left: 68%;
	}
}

.card {
	display: flex !important;
	flex-direction: column !important;
	background-color: rgb(255, 255, 255) !important;
	border: 0px solid transparent !important;
	border-radius: 0.25rem !important;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px -1px;
	color: rgb(34, 43, 69) !important;
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.25rem;
	margin-bottom: 1.875rem;
	padding: 0 1rem 1rem;
}
.card.card-h100 {	
	padding: 0 1rem !important;
	height: 100% !important;
}
.card .heading {
	padding: 1rem 0;
	border-bottom: 1px solid rgb(237, 241, 247);
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	color: rgb(34, 43, 69);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-size: 0.9375rem;
	font-weight: 600;
	line-height: 1.5rem;
	display: flex;
	justify-content: space-between;
}

.card footer {
	padding: 1rem;
	border-top: 1px solid rgb(237, 241, 247);
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.25rem;
}

.card footer a.btn-view {
	color: var(--bg-purple);
	font-size: 0.9375rem;
	font-weight: 600;
	line-height: 1.25rem;
	text-decoration: none;
	border-bottom: 1px solid currentColor;
	float: right;
}
.card footer a:hover {
	color: rgb(34, 43, 69);
}

.user_dropdown_btn .dropdown .dropdown-toggle:focus,
.dropdown-toggle:active {
	outline: none !important;
	box-shadow: none;
}

.dropdown-menu {
	padding: 4px 0px;
	list-style-type: none;
	background-color: #ffffff;
	background-clip: padding-box;
	border-radius: 10px;
	border: none !important;
	outline: none;
	box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
		0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	font-size: 14px;
}
.dropdown-menu .dropdown-item {
	clear: both;
	margin: 0;
	padding: 7.5px 20px;
	color: #455560;
	font-weight: normal;
	font-size: 14px;
	line-height: 1.5714285714285714;
	cursor: pointer;
	transition: all 0.2s;
	border-radius: 6px;
	position: relative;
	display: flex;
	align-items: center;
}

/* Theme Color */

.form-control {
	margin: 0;
	padding: 8px 11px;
	color: #455560;
	font-size: 14px;
	line-height: 1.5714285714285714;
	list-style: none;
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 0;
	background-color: #ffffff;
	background-image: none;
	border-width: 1px;
	border-style: solid;
	border-color: #e6ebf1;
	border-radius: 10px;
	transition: all 0.2s;
}

/* PAS Theme */
.bg-purple {
	background-color: var(--bg-purple) !important;
}
.bg-pink {
	background-color: var(--bg-pink) !important;
}
.bg-blue {
	background-color: var(--bg-blue) !important;
}
.bg-red {
	background-color: var(--bg-red) !important;
}

.bg-shade-pink {
	background-color: var(--bg-shade-pink-rgba) !important;
}

.bg-shade-purple {
	background-color: var(--bg-shade-purple-rgba) !important;
}

.bg-shade-dark-blue {
	background-color: var(--bg-shade-dark-blue-rgba) !important;
}

.bg-shade-light-blue {
	background-color: var(--bg-shade-light-blue-rgba) !important;
}

.bg-shade-hazel-blue {
	background-color: var(--bg-shade-hazel-rgba) !important;
}

/* Apply styles on hover */
.btn:hover {
	/* Your hover styles */
}

/* Apply styles when focused */
.btn:focus,
.btn:focus-visible {
	box-shadow: inherit !important;
}

/* Apply styles when button is active */
.btn:active {
	/* Your active styles */
}

/* Apply styles when button is disabled */
.btn:disabled {
	background-color: #9d9ca6 !important;
	border-color: #85848d !important;
	color: #f2f2f2 !important;
}

.btn-primary {
	background-color: var(--bg-shade-light-blue-rgba) !important;
	border-color: rgba(2, 62, 114, 0.5) !important;
}
.btn-primary:focus,
.btn-primary:focus-visible {
	box-shadow: 0 0 0 0.25rem rgba(2, 62, 114, 0.5) !important;
}
.btn-secondary {
	background-color: var(--bg-shade-purple-rgba) !important;
	border-color: rgba(170, 159, 242, 0.5) !important;
}
.btn-secondary:focus,
.btn-secondary:focus-visible {
	box-shadow: 0 0 0 0.25rem rgba(170, 159, 242, 0.5) !important;
}
.btn-success {
	background-color: #04d976 !important;
	border-color: currentColor !important;
}
.btn-danger {
	background-color: #f2561d !important;
	border-color: currentColor !important;
}
.btn-warning {
	background-color: #f2cc0c !important;
	border-color: currentColor !important;
}
.btn-info {
	background-color: #5068f2 !important;
	border-color: currentColor !important;
}
.btn-light {
	background-color: #f8f9fa !important;
	border-color: currentColor !important;
}
.btn-dark {
	background-color: #1d1c26 !important;
	border-color: currentColor !important;
}
.btn-link {
	color: #8d69bf !important;
}
.btn-default {
	background-color: #f2f2f2 !important;
	border-color: rgba(204, 204, 204, 0.5) !important;
}
.btn-default:focus,
.btn-default:focus-visible {
	box-shadow: 0 0 0 0.25rem rgba(204, 204, 204, 0.5) !important;
}
.btn-outline-primary {
}
.btn-outline-secondary {
	border-color: #088c4f !important;
}
.btn-outline-success {
	border-color: #088c4f !important;
}
.btn-outline-danger {
	border-color: #c74516 !important;
}
.btn-outline-warning {
	border-color: #c7a70a !important;
}
.btn-outline-info {
}
.btn-outline-light {
}
.btn-outline-dark {
}


/* Table */
/* .table thead tr th {
	background-color: var(--bg-purple) !important;
    color: #fff !important;
	font-weight: 600;
} */

.table thead tr th:first-child {
	border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.table thead tr th:last-child {
	border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.table > :not(:last-child) > :last-child > * {
	/* border-bottom-color: #c1cbd7; */
	border-bottom: none;
}
.table tbody th {
	color: rgb(126, 135, 165) !important;
	font-weight: 600;
}

.badge {
	font-weight: 600 !important;
}