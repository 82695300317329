#base {
    background-color: #f0f0f0;
    padding: 20px;
  }
  #chart-card {
    height: 100%; /* Adjust the height as needed */
    overflow: auto;
  }
  .skill-button{
    margin-right: 5%;
  }