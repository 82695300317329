*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;

  font-family: "Lexend Deca", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

  font-size: 1rem;
  line-height: 1.4;
  color: #3c3c3c;
  background-color: #fafafa;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.012em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  margin: 0;
}

p {
  margin: 0;
}
a {
  text-decoration: none;
  color: #6a6a6a;
}
a:hover,
a:focus {
  text-decoration: underline;
}

:focus {
  outline: 0.125rem solid #6a6a6a;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Button */
.button-primary {
  background: rgb(112, 65, 223) !important;
  color: #fff !important;
}
.button-primary:hover,
.button-primary:focus,
.button-primary:active {
  background: rgb(112, 65, 223) !important;
  color: #fff !important;
}
.button-primary:focus,
.button-primary:active {
  box-shadow: inset 0em 0.1em 1em rgba(0, 0, 0, 0.3);
}

.navbar,
.footer {
  width: 100%;
  padding: 1rem 0.5rem;
  background-color: #dadada;
  text-align: center;
  font-weight: 700;
}

#loginScreen {
  width: 100%;
  height: 100vh;
  background: #fff;
  overflow: hidden;
  display: flex;
}



/* Login Left */
.login-left {
  position: relative;
  z-index: 1;
  background-image: url('./Images/businessman-outdoors.png');
  background-repeat:  no-repeat;
  background-size: cover;
  background-position: right bottom;
  width: 100%;
  height: 100%;
}
.login-left:after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(127, 74, 252, 0.6);
  z-index: -1;
}

.login-content {
  padding: 8rem 2rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.login-content h1 {
  margin-bottom: 60px;
  font-weight: 800;
  font-size: 40px;
}
.login-content h3 {
  font-size: 26px;
  font-weight: 600;
}
.login-navigation {
  position: absolute;
  bottom: 54px;
  justify-content: center;
  display: flex;
  width: 100%;
}
.login-navigation ul {
  display: flex;
  gap: 30px;
}
.login-content,
.login-navigation ul a {
  color: #fff;
}
.login-navigation ul a {
  transition: all 0.35s ease;
  position: relative;
  padding: 0.5em 0.8em;
  text-decoration: none;
}
.login-navigation ul a:hover,
.login-navigation ul a:focus {
  text-decoration: none;
  border: none;
  box-shadow: none;
  outline: none;
}

.login-navigation ul a::before,
.login-navigation ul a::after {
  content: "";
  height: 14px;
  width: 14px;
  position: absolute;
  transition: all 0.35s ease;
  opacity: 0;
}

.login-navigation ul a::before {
  content: "";
  right: 0;
  top: 0;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: translate(-100%, 50%);
}

.login-navigation ul a:after {
  content: "";
  left: 0;
  bottom: 0;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
  transform: translate(100%, -50%);
}

.login-navigation ul a:hover:before,
.login-navigation ul a:hover:after {
  transform: translate(0, 0);
  opacity: 1;
}

.login-navigation ul a:hover {
  color: #fff;
}

/* Login Right */
.login-right {
  width: 100%;
  color: #000000;
}

.auth-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.auth-screen h2 {
  font-weight: 500;
  line-height: 45px;
}
.auth-screen p {
  line-height: 25px;
  margin-top: 18px;
  margin-bottom: 40px;
}

.auth-screen form {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.auth-screen .form-control {
  background: #eaeaea;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.8);
  padding: 10px 20px;
  border-right: 0;
  font-size: 16px;
}
.auth-screen .form-control::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.auth-screen .input-group-text {
  background: #eaeaea;
}

.form-check-label,
.forgot-password {
  font-size: 14px;
}
.forgot-password {
  color: #550ffd;
  cursor: pointer;
}
.button-primary {
  font-size: 18px;
}
.button-icon svg {
  width: 22px;
}
.signup-text {
  font-size: 14px;
}
.signup-text a {
  color: #550ffd;
}
.login-right .need-help {
  position: absolute;
  bottom: 50px;
  right: 50px;
  font-size: 16px;
}

.input-icon svg {
  width: 22px;
}
@media (width > 992px) {
  .carousel {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
  }
  .carousel .carousel-caption,
  .carousel .carousel-indicators {
    position: static;
  }
  .carousel .carousel-caption h3 {
    display: none;
  }
  .carousel .carousel-indicators {
    gap: 8px;
  }
  .carousel .carousel-indicators [data-bs-target] {
    background-color: #d9d9d9;
    opacity: 1;
    width: 24px;
    height: 6px;
    border-radius: 8px;
    border: none;
  }
  .carousel .carousel-indicators button.active {
    background-color: #ff0141;
  }

  .h-100vh {
    height: 100vh;
  }
}
@media (width < 991px) {
  #loginScreen {
    flex-direction: column;
    justify-content: center;
  }
  #loginScreen > div {
    width: 100%;
  }
  #loginScreen > .login-left {
    display: none;
  }
  .login-left .login-navigation {
    position: static;
    padding-bottom: 1em;
  }

  .login-right .auth-screen form {
    width: auto;
  }

  .login-right .need-help {
    bottom: 25px;
    right: 25px;
  }
}


.w-lg-50 {
  max-width: 50% !important;
}
.alert {
  padding: 0.6rem 0.8rem;
}
