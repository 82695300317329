
* {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

h1 {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: underline;
    font-size: 14pt;
}

h2 {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

.p,
p {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 13pt;
    margin: 0pt;
}

.s1 {
    color: #FFF;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

.s2 {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

a {
    color: #0462C1;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 12pt;
}

li {
    display: block;
}

#l1 {
    padding-left: 0pt;
    counter-reset: c1 1;
}

#l1>li>*:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal)") ";
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

#l1>li:first-child>*:first-child:before {
    counter-increment: c1 0;
}

#l2 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#l2>li>*:first-child:before {
    counter-increment: c2;
    content: counter(c2, lower-latin)". ";
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

#l2>li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#l3 {
    padding-left: 0pt;
}

#l3>li>*:first-child:before {
    content: " ";
    color: black;
    font-family: Symbol, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l4 {
    padding-left: 0pt;
}

#l4>li>*:first-child:before {
    content: " ";
    color: black;
    font-family: Symbol, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l5 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#l5>li>*:first-child:before {
    counter-increment: c2;
    content: counter(c2, lower-latin)". ";
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

#l5>li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#l6 {
    padding-left: 0pt;
    counter-reset: c3 1;
}

#l6>li>*:first-child:before {
    counter-increment: c3;
    content: counter(c3, lower-roman)". ";
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l6>li:first-child>*:first-child:before {
    counter-increment: c3 0;
}

#l7 {
    padding-left: 0pt;
    counter-reset: c3 1;
}

#l7>li>*:first-child:before {
    counter-increment: c3;
    content: counter(c3, lower-roman)". ";
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l7>li:first-child>*:first-child:before {
    counter-increment: c3 0;
}

li {
    display: block;
}

#l8 {
    padding-left: 0pt;
    counter-reset: f1 1;
}

#l8>li>*:first-child:before {
    counter-increment: f1;
    content: counter(f1, lower-roman)". ";
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l8>li:first-child>*:first-child:before {
    counter-increment: f1 0;
}

table,
tbody {
    vertical-align: top;
    overflow: visible;
    width: 80%;
}
