*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.app_history_container_content {
    /* display: flex; */
    justify-content: space-between;
    padding-left: 18px;
    padding-bottom:2%;
}

.tab-content {
    background-color: #fff;
    height: auto;
}

.filter_buttons button#dropdownMenu2 {
    padding: 0px;
    width: 57px;
    height: 22px;
    background-color: #C8DEFF;
    color: black;
    border: #C8DEFF;
    font-weight: 600;
}

.container_body {
    background-color: #EBF2FC;
    height: auto;
    padding: 12px;
}

span.heading {
    font-family: system-ui;
    color: #000000;
    font-size: 14px;
    font-weight: 700;
}

/* .nav-tabs .nav-item .nav-link.active.custom-active-tab {
    color: #7F1AFC !important;
    text-decoration: underline !important;
    background-color: transparent !important;
    border: none !important;
    text-underline-offset: 0.8em;
} */

/* .nav-tabs .nav-link {
    color: black;
    background-color: transparent !important;
    border: none !important;
    font-weight: 600;
} */

.custom-table {
   
    border-collapse: collapse;
    margin-top: 20px; 
    vertical-align: middle;

}

.custom-table td, .custom-table th {
    border: none;
    padding: 6px; 
    text-align: left;
}
.custom-table td {
    padding: 6px; 
    text-align: left;
}
.custom-table th {
    background-color: #f2f2f2;
}
.three-dots {
    position: absolute;
    right: 0;
    cursor: pointer;
    padding-right: 14rem;
}
@media (max-width: 768px) {
    .custom-table {
        display: block;
        width: 100%;
    }

    .text,
    .divider {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
    }

    .three-dots {
        position: relative;
        margin-top: 10px; 
    }
}


  