
.inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 5rem; 
  }
  
 
  .wrapper {
    position: absolute;
    display: flex;
  }
  
 
  .item {
    /* max-width: 150px;  */
    /* height: 5rem;  */
    padding: 2% 1% 0%;
    /* white-space: nowrap;  */
  }
  
 
  .item:last-child {
    padding-right: 0;
  }
  

  .section_container {
    display: flex;
    animation: swipe var(--speed) linear infinite;
  }
  
  @keyframes swipe {
    0% {
      transform: translateX(100%); 
    }
  
    100% {
      transform: translateX(-100%); 
    }
  }
  