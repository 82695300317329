* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.card-body {
	color: white;
}
.stat-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.stat-info .stat-text {
	color: #fff;
}
.stat-info .stat-icon {
	padding: 1em;
	background: rgba(0, 0, 0, 20%);
	border-radius: 50%;
}
@media (width < 991px) {
	.content-area {
		overflow-y: auto;
		height: 100vh;
		flex: 1 0 0%;
		min-width: 0px;
		/* padding: 2.25rem 1.25rem 0.75rem; */
	}
	.dashboard_container {
		flex-direction: column;
		margin: 0;
	}
	.card {
		width: 100% !important;
	}

	.card .row {
		gap: 10px;
		margin-bottom: 10px;
	}

	.display-card {
		width: 100% !important;
		margin: 20px 0;
	}
}

hr {
	margin-left: 11px;
	width: 94%;
}
.reviewed-all {
	border-bottom: 4px solid green;
}
.yet-to-submit {
	border-bottom: 4px solid #feda79;
}

.in-progress {
	border-bottom: 4px solid #fc8a28;
}
/* a {
    margin-left: 65%;
    color: #000000;
    font-size: 14px;
    font-weight: bold;
} */

.closed {
	font-size: 10px;
	position: relative;
	display: inline-block;
	padding-left: 15px;
}
.closed::before {
	content: "";
	position: absolute;
	float: left;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 10px;
	height: 10px;
	background-color: #ff418b;
	border-radius: 2px;
}
.open {
	font-size: 10px;
	position: relative;
	display: inline-block;
	padding-left: 15px;
}
.open::before {
	content: "";
	position: absolute;
	float: left;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 10px;
	height: 10px;
	background-color: #7c41f7;
	border-radius: 2px;
}
.inner-container {
	width: 40rem;
	float: left;
}
.appraisal-view {
	float: right;
	width: 94px;
}
.appraisal-view-send {
	float: right;
	color: #9194bc;
	font-weight: bold;
	font-size: 14px;
	margin-right: 18px;
}
.appraisal-text {
	font-size: 16px;
	margin: 11px;
}

.col-sm.status_box {
	display: flex;
	flex-direction: column;
	padding: 0px;
	gap: 6px;
	/* justify-content: space-between; */
	margin-top: 11px;
}
.status_content {
	display: flex;
	justify-content: space-between;
	margin-left: 13px;
	margin-right: 13px;
}

.parameterstableheader th {
	color: white !important;
}
