.pagination-container ml-auto
{
  float: left;
}
#viewbutton{
border-radius: 25px;
background-color: rgb(249, 67, 137);
color: white;
border: none;
}
#viewbutton:hover {
  transform: scale(1.1); 
  transition: transform 0.2s ease; 
}
.viewcount:hover{
  cursor: pointer;
}
#filter
{
  cursor: pointer;
  border-radius: 10px;
}
#filter:hover {
  transform: scale(1.1); 
  transition: transform 0.2s ease;
}

@media (min-width: 1400px) {
  .dashboard-container {
      max-width: 1600px !important;
  }
}
