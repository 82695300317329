.Hover :hover{
    cursor: pointer;

}
.team_table tbody tr:hover{
    cursor: pointer;

    
}
.team_table th {
    background-color: var(--bg-purple) !important;
    color: #fff !important;
    border: none;
  }
  @media (min-width: 1400px) {
    .teams-container {
        max-width: 1600px !important;
    }
}
