.hero {
    background-color: #C8DEFF;
    height: 200px;
    margin-left: 20px;
    margin-right: 30px;
}

.textNow {
    margin-left: 20px;
}

.statusText {
    font-size: 22px;
    font-weight: 600;
    border-right: 2px solid #fff;
    padding: 20px;
    text-align: center;
}
  
  .statusText:last-child {
    border-right: none;
  }

  .statusCircle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .colorGreen {
    background-color: green;
  }

  .colorOrange {
    background-color: orange;
  }

  .buttonDetail {
    background-color: #C8DEFF !important;
    color: black !important;
    border: none !important;
    border-radius: 0% !important;
    padding: 4px !important;
  }
